<template>
  <v-textarea
    v-model="model"
    :label="label"
    :placeholder="placeholder || ''"
    :error-messages="errors"
    outlined
    :rounded="rounded"
    :solo="solo"
    flat
    :disabled="disabled"
    :readonly="readonly"
    :dense="dense"
    :dark="darkEnabled"
    @input="onChange"
    @blur="onBlur"
    :rows="rows"
    :maxlength="maxlength || undefined"
    :no-resize="noResize"
  ></v-textarea>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'FormText',
  props: {
    value: {
      type: [String],
      default: '',
      required: false
    },
    label: {
      type: String,
      default: '',
      required: false
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    dense: {
      type: Boolean,
      default: true,
      required: false
    },
    rounded: {
      type: Boolean,
      default: true,
      required: false
    },
    solo: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false
    },
    errorMessage: {
      type: [Object],
      default: () => ({}),
      required: false
    },
    validation: {
      type: Object,
      default: () => null,
      required: false
    },
    rows: {
      type: Number,
      default: 5,
      required: false
    },
    maxlength: {
      type: Number,
      required: false
    },
    noResize: {
      type: Boolean,
      default: false,
      required: false
    },
    personalizedMessage: {
      type: String,
      required: false
    }
  },
  data: () => ({
    visibled: false,
    model: '',
    touched: false
  }),
  created() {
    this.model = this.value
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (newVal != oldVal && newVal != this.model) {
          this.model = newVal
        }
      }
    },
    validation: {
      deep: true,
      handler(newVal) {
        this.touched = newVal.$dirty
      }
    }
  },
  computed: {
    ...mapGetters(['darkEnabled']),
    errors() {
      if (this.validation && (this.validation.$dirty || this.touched)) {
        let errorsMess = []
        if (
          (!this.validation.customRequired && this.validation.$error) ||
          (!this.validation.required && this.validation.$error)
        ) {
          errorsMess.push(
            this.errorMessage['required'] || this.personalizedMessage
              ? this.personalizedMessage
              : 'Campo obrigatório'
          )
        }
        return errorsMess
      }
      return []
    }
  },
  methods: {
    onChange() {
      this.$emit('change', this.model)
      this.$emit('input', this.model)
      this.checkValidation()
    },
    onBlur() {
      this.$emit('blur', this.model)
      this.checkValidation()
    },
    checkValidation() {
      if (this.validation) {
        this.validation.$touch()
        this.touched = true
      }
    }
  }
}
</script>

<style></style>
