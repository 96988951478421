<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--secondary-color)">
      <v-row>
        <v-col cols="12" xs="12" xm="6" md="6">
          <FormSelect
            label="Canal de comunicação"
            v-model="form.alias_canal"
            :options="canais"
            :validation="$v.form.alias_canal"
            @input="getEventosCanal"
          />
          <FormSelect
            label="Evento"
            v-model="form.alias_evento"
            :options="eventos"
            :validation="$v.form.alias_evento"
            @input="getMensagem"
          />
          <FormSwitch v-model="form.ativo" label="Ativar envio" />

          <template v-if="form.alias_canal === 'whatsapp'">
            <FormSelect
              label="Template"
              v-model="form.template_id"
              :options="listaTemplates"
            />
          </template>
          <template v-if="form.alias_canal === 'whatsapp_blip'">
            <FormField label="Namespace" v-model="form.metadados.namespace" />
            <FormField
              label="Template"
              v-model="form.metadados.template_name"
            />
          </template>
          <template
            v-if="
              form.alias_canal != 'whatsapp' &&
                form.alias_canal != 'whatsapp_blip'
            "
          >
            <FormText
              label="Mensagem"
              v-model="form.mensagem"
              :validation="$v.form.mensagem"
              :rows="15"
            />
          </template>
        </v-col>
        <v-col
          class="text-left"
          cols="12"
          xs="12"
          xm="6"
          md="6"
          v-if="mensagemData"
        >
          <v-col cols="12" v-if="form.permite_html">
            <p class="font-weight-black">Prévia</p>
            <p>
              Este evento suporta mensagens em html, confira a baixo uma prévia
              da sua mensagem.
            </p>
            <iframe class="iframe-preview" :srcdoc="form.mensagem"></iframe>
          </v-col>
          <v-col cols="12">
            <p class="font-weight-black">Descrição</p>
            <p>{{ mensagemData.descricao }}</p>
          </v-col>
          <v-col cols="12">
            <p class="font-weight-black">Variáveis</p>
            <p>
              Use das variáveis para incluir dados do banco de dados nas suas
              mensagens.
            </p>
            <p>
              Dentro da mensagem inclua as variáveis como um texto e no envio
              estas serão alteradas para um dado real.
            </p>
          </v-col>
          <v-col cols="12">
            <ul class="variable-list">
              <li
                class="variable-item"
                v-for="item in variveisDisponiveis"
                :key="item.variavel"
              >
                <span>{{ templateVar(item.variavel) }}</span> -
                {{ item.descricao }}
              </li>
            </ul>
          </v-col>
          <v-col
            cols="12"
            v-if="form.metadados && serviceWhatsapp === 'ZENVIA_WHATSAPP'"
          >
            <p class="font-weight-black">Informações do provedor</p>
            <p v-if="metadadoError">Erro: {{ metadadoError }}</p>
            <p v-if="metadadoStatus">{{ metadadoStatus }}</p>
            <p v-if="metadadoId">Template id: {{ metadadoId }}</p>
          </v-col>
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-col class="d-flex justify-end" cols="12"
          ><FormButton type="perigo" @click="onRestaura">Restaurar</FormButton>
          <FormButton
            type="submissao"
            @click="onSave"
            style="margin-left:5px"
            submit
            >Salvar</FormButton
          >
        </v-col>
      </v-row>
    </Panel>
  </div>
</template>

<script>
import Panel from '@/components/Panel'
import FormButton from '@/components/form/FormButton'
import FormSelect from '@/components/form/FormSelect'
import FormText from '@/components/form/FormText'
import FormField from '@/components/form/FormField'
import { mapGetters } from 'vuex'
import Api from '../../service/api-web'
import { required } from 'vuelidate/lib/validators'
import { ERRORS_SHOW } from '@/store/actions/errors'
import * as _ from 'lodash'
import FormSwitch from '@/components/form/FormSwitch'
import MarketingModules from '@/components/MarketingModules'
import { ModulesEnum } from '@/store/actions/modulesEnum'

export default {
  name: 'MensagensAutomaticas',
  components: {
    Panel,
    FormButton,
    FormSelect,
    FormText,
    FormSwitch,
    FormField,
    MarketingModules
  },
  data: () => ({
    canais: [],
    eventos: [],
    form: {
      alias_canal: '',
      alias_evento: '',
      mensagem: '',
      permite_html: false,
      ativo: true,
      metadados: {},
      template_id: ''
    },
    mensagemData: null,
    variaveis: {},
    serviceEmail: 'ELASTIC_MAIL',
    serviceWhatsapp: 'ZENVIA_WHATSAPP',
    metadados: null,
    metadadoStatusLabel: {
      WAITING_REVIEW: 'Aguardando revisão do template',
      REJECTED: 'Template rejeitado',
      WAITING_APPROVAL: 'Aguardando aprovação do template',
      APPROVED: 'Template aprovado'
    },
    templates: [],
    modulosForm: [
      ModulesEnum.PAINEL_CLIENTE,
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.VENDAMAIS_WEB,
      ModulesEnum.VENDAMAIS_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP
    ]
  }),
  computed: {
    ...mapGetters(['getFornecedorId', 'moduloFornecedorAtivo']),
    variveisDisponiveis() {
      return (
        Object.keys(this.variaveis).map(key => ({
          variavel: key,
          descricao: this.variaveis[key] || ''
        })) || []
      )
    },
    provedoresAtivos() {
      return ['COMMERCE', this.serviceWhatsapp, this.serviceEmail]
    },
    metadadoStatus() {
      return _.get(
        this.metadadoStatusLabel,
        _.get(this.form.metadados, 'status', null),
        null
      )
    },
    metadadoError() {
      return _.get(this.form.metadados, 'error', '')
    },
    metadadoId() {
      return _.get(this.form.metadados, 'id', '')
    },
    listaTemplates() {
      return (this.templates || []).map(item => ({
        text: `${item.name} - (${_.get(
          this.metadadoStatusLabel,
          item.status,
          null
        )})`,
        value: item.id
      }))
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  validations: {
    form: {
      alias_canal: { required },
      alias_evento: { required },
      mensagem: { required }
    }
  },
  watch: {
    'form.alias_canal': {
      handler() {
        if (
          this.form.alias_canal == 'whatsapp' &&
          this.serviceWhatsapp === 'ZENVIA_WHATSAPP'
        ) {
          this.getTemplates(this.form.alias_canal)
        }
      }
    },
    getFornecedorId: {
      handler() {
        if (
          this.form.alias_canal == 'whatsapp' &&
          this.serviceWhatsapp === 'ZENVIA_WHATSAPP'
        ) {
          this.getTemplates(this.form.alias_canal)
        }
      }
    }
  },
  created() {
    Api.v4.mensagensautomaticas
      .listaCanais()
      .then(resp => {
        this.canais = (resp.data || []).map(canal => ({
          text: canal.canal,
          value: canal.alias_canal
        }))
      })
      .catch(err => {
        this.$store.dispatch(ERRORS_SHOW, err)
      })
    Api.v2.marketing.email
      .getConfig(this.getFornecedorId)
      .then(resp => {
        this.serviceEmail = _.get(resp, 'data.service', 'ELASTIC_MAIL')
      })
      .catch(err => {
        this.$store.dispatch(ERRORS_SHOW, err)
      })
    Api.v2.marketing.whatsapp
      .getConfig(this.getFornecedorId)
      .then(resp => {
        this.serviceWhatsapp = _.get(resp, 'data.service', 'ZENVIA_WHATSAPP')
      })
      .catch(err => {
        this.$store.dispatch(ERRORS_SHOW, err)
      })
  },
  methods: {
    getEventosCanal() {
      this.form = {
        ...this.form,
        alias_evento: null,
        mensagem: null,
        permite_html: false
      }
      this.mensagemData = null
      this.variaveis = {}
      this.$v.$reset()
      Api.v4.mensagensautomaticas
        .listaEventos(this.form.alias_canal)
        .then(resp => {
          this.eventos = (resp.data || []).reduce((eventos, evento) => {
            if (
              evento.provedores.some(p =>
                this.provedoresAtivos.includes(p.provedor)
              )
            ) {
              eventos.push({
                text: evento.evento,
                value: evento.alias_evento
              })
            }
            return eventos
          }, [])
        })
        .catch(err => {
          this.$store.dispatch(ERRORS_SHOW, err)
        })
    },
    getMensagem() {
      Api.v4.mensagensautomaticas
        .obterMensagem(
          this.getFornecedorId,
          this.form.alias_canal,
          this.form.alias_evento
        )
        .then(resp => {
          this.form.mensagem = _.get(resp, 'data.mensagem', '')
          let findProvedor = _.get(resp, `data.provedores`, []).find(
            item => item.provedor == this.serviceEmail
          )
          this.form.permite_html = _.get(findProvedor, `permite_html`, false)
          this.form.ativo = _.get(resp, 'data.ativo', true)
          this.mensagemData = resp.data
          this.variaveis = _.get(resp, 'data.variaveis', {})
          this.form.metadados = _.get(resp, 'data.metadados', {}) || {}
          this.form.template_id = _.get(
            resp,
            'data.metadados.template_id',
            null
          )
          const variaveisCondicionais = _.get(
            resp,
            'data.variaveis_provedor',
            []
          )
          variaveisCondicionais.forEach(item => {
            if (this.provedoresAtivos.includes(item.provedor)) {
              this.variaveis = {
                ...this.variaveis,
                [item.value]: item.name
              }
            }
          })
        })
        .catch(err => {
          this.$store.dispatch(ERRORS_SHOW, err)
        })
    },
    onSave() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      Api.v4.mensagensautomaticas
        .atualizaMensagem(
          this.getFornecedorId,
          this.mensagemData.idmensagemcomunicacao,
          {
            ...this.form,
            metadados: {
              ...this.form.metadados,
              template_id: this.form.template_id
            }
          }
        )
        .then(() => {
          this.$vueOnToast.pop(
            'success',
            'Mensagem automatica atualizada com sucesso'
          )
          this.getMensagem()
        })
        .catch(err => {
          this.$store.dispatch(ERRORS_SHOW, err)
        })
    },
    onRestaura() {
      if (!this.form.alias_canal || !this.form.alias_evento) {
        return
      }
      Api.v4.mensagensautomaticas
        .restauraPadrao(
          this.getFornecedorId,
          this.mensagemData.idmensagemcomunicacao
        )
        .then(() => {
          this.getMensagem()
          this.$vueOnToast.pop(
            'success',
            'Mensagem automatica atualizada com sucesso'
          )
        })
        .catch(err => {
          this.$store.dispatch(ERRORS_SHOW, err)
        })
    },
    templateVar(variable) {
      return `{{${variable}}}`
    },
    getTemplates(canalAlias) {
      Api.v4.mensagensautomaticas
        .templates(this.getFornecedorId, canalAlias)
        .then(resp => {
          this.templates = _.get(resp, 'data', [])
        })
        .catch(err => {
          this.$store.dispatch(ERRORS_SHOW, err)
        })
    }
  }
}
</script>

<style scoped>
@import '~vue2-editor/dist/vue2-editor.css';
.variable-list li span {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}
.iframe-preview {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 28px;
  height: 300px;
  overflow-x: auto;
  margin-top: 14px;
}
</style>
